
/**
 * Do not edit directly
 * Generated on Fri, 17 Jan 2025 14:36:38 GMT
 */

$light-theme: (
  'wri-ink-base': #070707,
  'wri-ink-light': #4A4A4A,
  'wri-ink-lightest': #757575,
  'wri-ink-disabled': #A1A1A1,
  'wri-bg-dark': #6C6C6C,
  'wri-bg-base': #E0E0E0,
  'wri-bg-light': #F4F4F2,
  'wri-bg-lightest': #FFFFFF,
  'wri-bg-special-darker': #7E782E,
  'wri-bg-special-dark': #8F8946,
  'wri-bg-special-base': #C5C19B,
  'wri-bg-special-light': #E0E1CA,
  'wri-action-dark': #004324,
  'wri-action-base': #006938,
  'wri-spice-spicy': #D82525,
  'wri-spice-medium': #FD8809,
  'wri-spice-mild': #006938,
  'wri-elevation-low-up-x': 0,
  'wri-elevation-low-up-y': -2,
  'wri-elevation-low-up-blur': 10,
  'wri-elevation-low-up-spread': 0,
  'wri-elevation-low-up-color': #00000026,
  'wri-elevation-low-up-type': dropShadow,
  'wri-elevation-low-down-x': 0,
  'wri-elevation-low-down-y': 2,
  'wri-elevation-low-down-blur': 10,
  'wri-elevation-low-down-spread': 0,
  'wri-elevation-low-down-color': #00000026,
  'wri-elevation-low-down-type': dropShadow,
  'wri-elevation-high-up-x': 0,
  'wri-elevation-high-up-y': -5,
  'wri-elevation-high-up-blur': 24,
  'wri-elevation-high-up-spread': 0,
  'wri-elevation-high-up-color': #00000029,
  'wri-elevation-high-up-type': dropShadow,
  'wri-elevation-high-down-x': 0,
  'wri-elevation-high-down-y': 10,
  'wri-elevation-high-down-blur': 24,
  'wri-elevation-high-down-spread': 0,
  'wri-elevation-high-down-color': #0000003d,
  'wri-elevation-high-down-type': dropShadow,
  'wri-error-base': #BB0000,
  'wri-error-light': #F8E6E6,
  'wri-gradient-redorange': conic-gradient(from 180deg at 50% 50%, #FD8809 -103.12deg, #AA3B2C 51.78deg, #FD8809 256.87deg, #AA3B2C 411.78deg),
  'wri-positive-light': #D1E8DA,
  'wri-positive-base': #178C48
);
